<template>
  <div>
    <div
      v-if="userData"
      class="fxt-desk d-md-block d-none"
    >
      <div class="fxt-side text-center">
        <div
          class="cursor-pointer"
          @click="pageLotto"
        >
          <div>
            <i
              class="far fa-store"
              style="font-size: 18px"
            />
          </div>

          <p class="mb-0 mt-50">
            ซื้อสลาก
          </p>
        </div>
      </div>

      <div class="fxt-side text-center">
        <div
          class="cursor-pointer"
          @click="pageOrder"
        >
          <div>
            <i
              class="far fa-file-invoice"
              style="font-size: 18px"
            />
          </div>

          <p class="mb-0 mt-50">
            สลากฯของฉัน
          </p>
        </div>
      </div>

      <div class="fxt-side text-center">
        <div
          class="cursor-pointer"
          @click="pageProfile"
        >
          <div>
            <i
              class="far fa-user-circle"
              style="font-size: 18px"
            />
          </div>

          <p class="mb-0 mt-50">
            โปรไฟล์
          </p>
        </div>
      </div>

      <div class="fxt-side text-center">
        <div
          class="cursor-pointer"
          @click="pageCart"
        >
          <div>
            <i
              class="far fa-cart-arrow-down cart-item"
              style="font-size: 18px"
            >
              <span
                v-if="MyCart.length > 0 || box6.length > 0 || box2b.length > 0"
                class="cart-count"
              >
                {{ MyCart.length + box6.length + box2b.length }}
              </span>
            </i>
          </div>

          <p class="mb-0 mt-50">
            ตะกร้า
          </p>

          <p
            v-if="MyCart.length > 0 || box6.length > 0 || box2b.length > 0"
            class="mb-0 timecount"
          >
            {{ CountTimeShow ? CountTimeShow : "00 : 00" }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="userData"
      class="fxt-mb d-block d-md-none"
    >
      <div class="row">
        <div class="col p-25">
          <div
            class="text-center"
            @click="$router.push({ name: 'check-result' })"
          >
            <img
              src="@/assets/images/newIcon/ic_menu/search.png"
              alt=""
              height="27"
            >

            <p class="ftx-txt mb-0 text-primary mt-25">
              ตรวจรางวัล
            </p>
          </div>
        </div>

        <div class="col p-25">
          <div
            class="text-center"
            @click="$router.push({ name: 'order' })"
          >
            <img
              src="@/assets/images/newIcon/ic_menu/bag.png"
              alt=""
              height="27"
            >
            <p class="ftx-txt mb-0 text-primary mt-25">
              กระเป๋าสลาก
            </p>
          </div>
        </div>

        <div class="col p-25">
          <div
            class="text-center"
            @click="$router.push({ name: 'lottery' })"
          >
            <img
              src="@/assets/images/newIcon/ic_menu/ticket.png"
              alt=""
              height="27"
            >

            <p class="mb-0 text-primary mt-25 p-buy">
              ซื้อสลาก
            </p>
          </div>
        </div>

        <div class="col p-25">
          <div
            class="text-center"
            @click="$router.push({ name: 'bank' })"
          >
            <img
              src="@/assets/images/newIcon/ic_menu/deposit.png"
              alt=""
              height="27"
            >

            <p class="ftx-txt mb-0 text-primary mt-25">
              ฝากเงิน
            </p>
          </div>
        </div>

        <div class="col p-25">
          <div
            class="text-center"
            @click="$router.push({ name: 'cart' })"
          >
            <span class="cart-item">
              <img
                src="@/assets/images/newIcon/ic_menu/cart.png"
                alt=""
                height="27"
              >

              <span
                v-if="MyCart.length > 0 || box6.length > 0 || box2b.length > 0"
                class="cart-count-2"
              >
                {{ MyCart.length + box6.length + box2b.length }}
              </span>
            </span>

            <p
              v-if="MyCart.length > 0 || box6.length > 0 || box2b.length > 0"
              class="timecount-2 mt-25"
            >
              {{ CountTimeShow ? CountTimeShow : "00 : 00" }}
            </p>

            <p
              v-else
              class="ftx-txt mb-0 text-primary mt-25"
            >
              ตะกร้า
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="fxt-mb d-block d-md-none"
    >
      <div class="row">
        <div class="col p-25">
          <div
            class="text-center"
            @click="$router.push({ name: 'check-result' })"
          >
            <img
              src="@/assets/images/newIcon/ic_menu/search.png"
              alt=""
              height="27"
            >

            <p class="ftx-txt mb-0 text-primary mt-25">
              ตรวจรางวัล
            </p>
          </div>
        </div>

        <div class="col p-25">
          <div
            class="text-center"
            @click="$refs['my-welcome'].show()"
          >
            <img
              src="@/assets/images/newIcon/ic_menu/bag.png"
              alt=""
              height="27"
            >

            <p class="ftx-txt mb-0 text-primary mt-25">
              กระเป๋าสลาก
            </p>
          </div>
        </div>

        <div class="col p-25">
          <div
            class="text-center"
            @click="$refs['my-welcome'].show()"
          >
            <img
              src="@/assets/images/newIcon/ic_menu/ticket.png"
              alt=""
              height="27"
            >

            <p class="mb-0 text-primary mt-25 p-buy">
              ซื้อสลาก
            </p>
          </div>
        </div>

        <div class="col p-25">
          <div
            class="text-center"
            @click="$refs['my-welcome'].show()"
          >
            <img
              src="@/assets/images/newIcon/ic_menu/deposit.png"
              alt=""
              height="27"
            >

            <p class="ftx-txt mb-0 text-primary mt-25">
              ฝากเงิน
            </p>
          </div>
        </div>

        <div class="col p-25">
          <div
            class="text-center"
            @click="$refs['my-welcome'].show()"
          >
            <img
              src="@/assets/images/newIcon/ic_menu/cart.png"
              alt=""
              height="27"
            >

            <p class="ftx-txt mb-0 text-primary mt-25">
              ตะกร้า
            </p>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="my-welcome"
      hide-footer
      hide-header
      centered
    >
      <div class="text-center">
        <div class="closed">
          <i
            class="far fa-times cursor-pointer icon-close"
            @click="$refs['my-welcome'].hide()"
          />
        </div>

        <h4 class="mb-0 text-primary mt-2">
          กรุณาเข้าสู่ระบบเพื่อทำการสั่งซื้อ
        </h4>

        <button
          class="btn-gray-txt p-75 w-75 my-2"
          @click="$refs['my-welcome'].hide(), $router.push({ name: 'login' })"
        >
          ไปที่หน้าเข้าสู่ระบบ
        </button>
      </div>
    </b-modal>

    <!-- <div class="copy-right">
      Copyright 2022 @Suwarnaphomi Lottery | All Rights Reserved
    </div> -->
  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'

export default {
  name: 'ThisFooter',
  components: {
    BModal,
  },
  props: {
    addedIds: {
      type: Array,
      default: () => [],
    },
    // eslint-disable-next-line vue/require-default-prop
    box6ss: {
      type: Array,
      default: () => [],
    },
    box2bss: {
      type: Array,
      default: () => [],
    },
    countdownTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      MyCart: [],
      box6: [],
      box2b: [],
      boxall: 0,
      CountTimeShow: JSON.parse(localStorage.getItem('CountTimeShow'))
        ? JSON.parse(localStorage.getItem('CountTimeShow'))
        : '00 : 00',
      userData: JSON.parse(localStorage.getItem('userData')),
      sumprice: 0,
      price: null,
    }
  },
  watch: {
    addedIds: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler() {
        if (JSON.parse(localStorage.getItem('MyCart'))) {
          this.MyCart = JSON.parse(localStorage.getItem('MyCart'))
          this.CalTime()
          this.CalPrice()
        }
      },
    },
    box2bss: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler() {
        // console.log('box2bss')
        if (JSON.parse(localStorage.getItem('box2b'))) {
          this.box2b = JSON.parse(localStorage.getItem('box2b'))
          // console.log(this.box2b)
        }
        this.CalTime()
        this.CalPrice()
      },
    },
    box6ss: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler() {
        // console.log('box6ss')
        if (JSON.parse(localStorage.getItem('box6'))) {
          this.box6 = JSON.parse(localStorage.getItem('box6'))
          // console.log(this.box6)
        }
        this.CalTime()
        this.CalPrice()
      },
    },
  },
  created() {
    // this.intervalClearMyCart = setInterval(() => {
    //   const time = moment().subtract(30, 'minutes')
    //   if (moment(this.MyCart[0].updated_at) < time) {
    //     console.log('clear')
    //     // eslint-disable-next-line no-underscore-dangle
    //     this.RemovelottoMycart(this.MyCart[0]._id)
    //     // ลบข้อมูลใน ใน this.MyCart array 0
    //     this.MyCart.splice(0, 1)
    //     // set ค่าใหม่ให้ localStorage
    //     localStorage.setItem('MyCart', JSON.stringify(this.MyCart))
    //   }
    // }, 3000)
  },
  beforeDestroy() {
    clearInterval(this.intervalID)
    // clearInterval(this.intervalClearMyCart)
  },
  mounted() {
    if (this.userData) {
      this.GetPrice()
    }
    const MyCartFromStorage = JSON.parse(localStorage.getItem('MyCart'))
    const box6FromStorage = JSON.parse(localStorage.getItem('box6'))
    const box2bFromStorage = JSON.parse(localStorage.getItem('box2b'))
    if (
      MyCartFromStorage.length > 0
      || box6FromStorage.length > 0
      || box2bFromStorage.length > 0
    ) {
      this.CalTime()
    }
  },
  methods: {
    GetPrice() {
      this.$http.get('lotto-suvarn/GetPrice').then(ress => {
        this.price = ress.data
        localStorage.setItem('sellprice', JSON.stringify(ress.data.sellprice))
        localStorage.setItem(
          'luckynum_price',
          JSON.stringify(ress.data.luckynum_price),
        )
      })
    },
    pageLotto() {
      if (this.userData) {
        this.$router.push({ name: 'lottery' })
      } else {
        this.$refs['my-welcome'].show()
      }
    },
    pageOrder() {
      if (this.userData) {
        this.$router.push({ name: 'order' })
      } else {
        this.$refs['my-welcome'].show()
      }
    },
    pageDeposit() {
      if (this.userData) {
        this.$router.push({ name: 'first-deposit' })
      } else {
        this.$refs['my-welcome'].show()
      }
    },
    pageCart() {
      if (this.userData) {
        this.$router.push({ name: 'cart' })
      } else {
        this.$refs['my-welcome'].show()
      }
    },
    pageProfile() {
      if (this.userData) {
        this.$router.push({ name: 'profile' })
      } else {
        this.$refs['my-welcome'].show()
      }
    },
    async CalTime() {
      // console.log(this.MyCart.length)
      if (this.MyCart.length > 0) {
        const index = this.MyCart[0].orderby.findIndex(x => x.username === this.userData.username)
        // eslint-disable-next-line vars-on-top, no-var, block-scoped-var, no-unused-vars
        var type = 'MyCart'
        // eslint-disable-next-line vars-on-top, no-var, block-scoped-var
        var check = this.MyCart[0].orderby[index].order_time
      } else if (this.box6.length > 0) {
        // eslint-disable-next-line vars-on-top, no-var, block-scoped-var, no-redeclare
        var type = 'box6'
        // eslint-disable-next-line vars-on-top, no-var, block-scoped-var, no-redeclare
        var check = this.box6[0].updated_at
      } else if (this.box2b.length > 0) {
        // eslint-disable-next-line vars-on-top, no-var, block-scoped-var, no-redeclare
        var type = 'box2b'
        // eslint-disable-next-line vars-on-top, no-var, block-scoped-var, no-redeclare
        var check = this.box2b[0].updated_at
      }
      const now = moment()
      // eslint-disable-next-line block-scoped-var
      const duration = moment.duration(now.diff(check))
      const Min = duration.asMinutes()
      // console.log(10 - Min)
      const time = moment().subtract(30, 'minutes')
      // eslint-disable-next-line block-scoped-var
      if (moment(check) < time) {
        // eslint-disable-next-line block-scoped-var
        if (type === 'MyCart') {
          // eslint-disable-next-line no-underscore-dangle
          this.RemovelottoMycart(this.MyCart[0]._id)
          // ลบข้อมูลใน ใน this.MyCart array 0
          this.MyCart.splice(0, 1)
          // set ค่าใหม่ให้ localStorage
          localStorage.setItem('MyCart', JSON.stringify(this.MyCart))
        }
        // eslint-disable-next-line block-scoped-var
        if (type === 'box6') {
          // eslint-disable-next-line no-underscore-dangle
          this.RemoveBoxMycart(this.box6[0]._id)
          // ลบข้อมูลใน ใน this.MyCart array 0
          this.box6.splice(0, 1)
          // set ค่าใหม่ให้ localStorage
          localStorage.setItem('box6', JSON.stringify(this.box6))
        }
        // eslint-disable-next-line block-scoped-var
        if (type === 'box2b') {
          // eslint-disable-next-line no-underscore-dangle
          this.RemoveBoxMycart(this.box2b[0]._id)
          // ลบข้อมูลใน ใน this.MyCart array 0
          this.box2b.splice(0, 1)
          // set ค่าใหม่ให้ localStorage
          localStorage.setItem('box2b', JSON.stringify(this.box2b))
        }

        clearInterval(this.intervalID)
        this.intervalID = null
        this.CountTimeShow = '00 : 00'
        this.CalPrice(1)
      } else {
        this.startTimer(60 * (30 - Min))
      }
    },
    async startTimer(duration) {
      let timer = duration
      if (timer > 0) {
        let minutes
        let seconds
        clearInterval(this.intervalID)
        this.intervalID = null
        this.intervalID = setInterval(() => {
          minutes = parseInt(timer / 60, 10)
          seconds = parseInt(timer % 60, 10)

          minutes = minutes < 10 ? `0${minutes}` : minutes
          seconds = seconds < 10 ? `0${seconds}` : seconds
          // console.log(seconds)
          this.CountTimeShow = `${minutes} : ${seconds}`
          // console.log(this.CountTimeShow)
          // eslint-disable-next-line no-plusplus
          if (--timer < 0) {
            timer = duration
          }
          if ((minutes === '00' && seconds === '00') || timer < 1) {
            clearInterval(this.intervalID)
            this.intervalID = null
            this.CountTimeShow = '00 : 00'
          }
        }, 1000)
      } else {
        this.CountTimeShow = '00 : 00'
      }
    },
    CalPrice() {
      if (
        this.MyCart.length > 0
        || this.box6.length > 0
        || this.box2b.length > 0
      ) {
        this.sumprice = 0
        this.MyCart.forEach(item => {
          // console.log(item)
          const index = item.orderby.findIndex(x => x.username === this.userData.username)
          if (index >= 0) {
            if (item.type === 0) {
              this.sumprice += JSON.parse(localStorage.getItem('sellprice')) * item.orderby[index].count
            } else {
              this.sumprice += JSON.parse(localStorage.getItem('luckynum_price')) * item.orderby[index].count
            }
          }
        })
        // console.log('ราคา')
        // console.log(this.sumprice)
        // eslint-disable-next-line no-unused-vars
        this.box6.forEach(item => {
          this.sumprice += item.price_discount
        })
        // eslint-disable-next-line no-unused-vars
        this.box2b.forEach(item => {
          this.sumprice += item.price_discount
        })
        localStorage.setItem('sumprice', JSON.stringify(this.sumprice))
        // console.log(this.sumprice)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
